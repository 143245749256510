.max-h-300 {
  max-height: 300px;
  overflow: auto;
}

.subsubsub {
  list-style: none;
  margin: 10px 0;
  padding: 0;
  font-size: 13px;
  color: #666;
  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    a.current {
      color: #000;
    }
  }
}

.flip-image {
  position: relative;
  .flip-item {
    position: absolute;
    bottom: 0;
    background: #fff;
    color: #000;
    padding: 10px;
  }
  .flip-right {
    right: 0;
  }
  .flip-left {
    left: 0;
  }
}

.upload-form {
  &.has-file {
    .file-wrap {
      border: 2px dashed #9e9e9e;
      h3 {
        display: block;
        text-align: center;
      }
    }
  }
  .file-wrap {
    h3 {
      display: none;
    }
  }
}

.template-download .preview img {
  height: auto !important;
}

.file-wrap .preview canvas {
  height: auto !important;
}

@media (max-width: 767px) {
  .input-group {
    display: block;
  }
  .bootstrap-select {
    width: 125px !important;

    .dropdown-menu {
      max-width: 300px !important;
    }
  }
  .media-left {
    display: block;
    padding-right: 0;
    .media-object {
      width: 100% !important;
    }
  }
  .media-body {
    padding-top: 10px;
  }
}
